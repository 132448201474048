<template>
  <div class="role-permission-wrapper">
    <ul class="item-name-wrapper">
      <li class="item-name"
          v-for="(item, index) in resource"
          :key="item.id"
          :class="{active: index === currentIndex, disabled: item.disabled}"
          @click="changeCenter(index, item)">
        {{item.name}}
        <span v-if="item.moduleColCheckedLength"
              class="dot"></span>
      </li>
    </ul>
    <div class="item-list-wrapper"
         v-for="(item, index) in resource"
         :key="item.id"
         :class="{active: index === currentIndex}">
      <div class="menu-wrapper">
        <div class="menu-list"
             v-for="menus in item.childMenus"
             :key="menus.id"
             :class="{active: menus.isSelected === 1}"
             @click="toggleMenu(menus, item)">{{menus.name}}</div>
        <!-- <template v-for="menus in item.childMenus">
          <template v-for="menu in menus.childMenus">
            <div class="menu-list"
                 v-for="mItem in menu.childMenus"
                 :key="mItem.id"
                 :class="{active: mItem.isSelected === 1}"
                 @click="toggleMenu(mItem, item)">{{mItem.name}}</div>
          </template>
        </template> -->
      </div>
      <div class="module-wrapper">
        <div v-for="menus in item.childMenus"
             :key="menus.id">
          <div class="module-list"
               v-show="menus.isSelected===1">
            <div class="modules-header">
              <div class="module module-0">
                <h3 class="inline-block v-middle mg_0 pl_20">{{menus.name}}</h3>
              </div>
              <div class="module module-1">
              </div>
            </div>
            <template v-for="menuItems in menus.childMenus">
              <div class="modules"
                   :key="menuItems.id">
                <div class="module module-0">
                  <h3 class="inline-block v-middle mg_0 pl_20">{{menuItems.name}}</h3>
                </div>
                <div class="module module-1">
                  <div class=""
                       v-for="menu in menuItems.childMenus"
                       :key="menu.id">
                    <span class="role-permission-btn"
                          @click="changeMenuSelectedVal(menu, menus, item)"
                          :class="{selected: menu.isSelected === 1}">
                      <span class="role-permission-text">{{menu.name}}</span>
                      <span class="role-permission-icon"></span>
                    </span>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    // 类型：增加（add）、修改（edit）和收权（back）三种类型
    handleType: {
      type: String,
      default: 'edit'
    },
    // 服务端接口返回的resource数据
    roleDataList: {
      type: Array,
      // `[]`
      default () {
        return []
      }
    },
    unifyId: {
      type: Number
    }
  },
  data () {
    return {
      currentIndex: 0,
      resource: []
    }
  },
  watch: {
    unifyId (id) {
      this.roleDataList && this.roleDataList.length && this.roleDataList.forEach(item => {
        if (item.id === id) {
          this.resource = item.childMenus || []
          this.currentIndex = 0
        }
      })
    },
    roleDataList (data) {
      this.getResultData(this.handleType === 'add')
      data && data.length && data.forEach(item => {
        if (item.id === this.unifyId) {
          this.resource = item.childMenus || []
        }
      })
      // this.currentIndex = 0
    }
  },
  methods: {
    // 选择结果返回的函数 如果init 为true需要把把选中的去掉
    getResultData (init) {
      let list = this.roleDataList || []
      let idArr = []
      let handleType = this.handleType
      list.length && list.forEach(source => {
        source.childMenus.length && source.childMenus.forEach((center, index) => {
          center.moduleColCheckedLength = 0
          center.childMenus.length && center.childMenus.forEach(level1 => {
            level1.childMenus.length && level1.childMenus.forEach(level2 => {
              if (init && (handleType === 'add')) {
                level1.isSelected = 0
              }
              level2.childMenus.length && level2.childMenus.forEach(level3 => {
                if (init && (handleType === 'add')) {
                  level3.isSelected = 0
                }
                if (level3.isSelected) {
                  idArr.push({
                    name: level3.name,
                    id: level3.id
                  })
                  center.moduleColCheckedLength++
                }
              })
            })
          })
        })
      })
      return idArr
    },
    changeCenter (index, item) {
      if (item.disabled) {
        return false
      }
      this.currentIndex = index
    },
    getRoleTypeCheckedLength (menu, tp) { // 获取菜单下已选中的权限的长度
      let len = 0
      if (menu && menu.childMenus && menu.childMenus.length) {
        if (tp) {
          menu.childMenus.forEach(child => {
            if (child.roleType === tp && child.isSelected === 1) {
              len++
            }
          })
        } else {
          menu.childMenus.forEach(child => {
            if (child.isSelected === 1) {
              len++
            }
          })
        }
      }
      return len
    },
    uncheckedRoleType (menus, item) { // 单个菜单下取消选中逻辑处理
      item.moduleColCheckedLength -= this.getRoleTypeCheckedLength(menus)
      menus.childMenus && menus.childMenus.length && menus.childMenus.forEach(v => {
        v.isSelected = 0
      })
    },
    toggleMenu (menus, item) { // 显示某个模块菜单或者隐藏某个模块菜单并取消权限选中
      if (item.disabled) {
        return false
      }
      let _this = this
      if (menus.isSelected === 1) { // 隐藏某个模块的权限并取消的逻辑处理
        item.moduleShowMenusLength--
        menus.isSelected = 0
        menus.childMenus && menus.childMenus.length && menus.childMenus.forEach(menuItem => {
          _this.uncheckedRoleType(menuItem, item)
        })
      } else { // 显示某个模块
        item.moduleShowMenusLength++
        menus.isSelected = 1
      }
    },
    changeMenuSelectedVal (menu, menus, item) {
      if (item.disabled) {
        return false
      }
      if (menu.isSelected === 1) {
        menu.isSelected = 0
        item.moduleColCheckedLength--
      } else { // 显示某个模块
        menu.isSelected = 1
        item.moduleColCheckedLength++
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.mg_0 {
  margin: 0;
}
.pd_20 {
  padding: 20px;
}
.pl_20 {
  margin-left: 20px;
}
.inline-block {
  display: inline-block;
}
.v-middle {
  vertical-align: middle;
}
.role-permission-wrapper {
  padding-top: 10px;
  text-align: left;
  height: 100%;
  .common-wrapper {
    display: flex;
    align-items: center;
    cursor: pointer;
    .outer {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
      border-radius: 4px;
      border: 1px solid #aaa;
      background-color: #fff;
      .inner {
        display: inline-block;
        width: 12px;
        height: 12px;
        border-radius: 4px;
        background-color: #7ac943;
      }
      .disabled {
        display: inline-block;
        width: 16px;
        height: 16px;
        background-image: url('./images/checkbox-disabled.png');
        background-repeat: no-repeat;
        background-size: 100%;
      }
    }
    .label {
      padding-left: 10px;
    }
  }
  .item-name-wrapper {
    display: flex;
    .item-name {
      width: 112px;
      height: 35px;
      text-align: center;
      line-height: 35px;
      background-color: #e3f0ff;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
      cursor: pointer;
      margin-left: 10px;
      position: relative;
      .dot {
        position: absolute;
        top: 5px;
        right: 10px;
        width: 10px;
        height: 10px;
        border-radius: 5px;
        background: #1b8cf2;
      }
      &.active {
        color: #fff;
        background: #1b8cf2;
        .dot {
          background: #fff;
        }
      }
      &.disabled {
        color: #333;
        background: #aaa;
        .dot {
          background: #fff;
        }
      }
    }
  }
  .item-list-wrapper {
    background: #fff;
    display: none;
    &.active {
      display: block;
    }
    .menu-wrapper {
      width: 90%;
      padding: 10px;
      border: 1px solid #eaeaea;
      border-radius: 5px;
      overflow: hidden;
      .menu-list {
        float: left;
        min-width: 80px;
        height: 36px;
        line-height: 36px;
        cursor: pointer;
        border-radius: 4px;
        border: 1px solid rgb(237, 236, 242);
        margin: 15px 0 0 20px;
        padding: 0 10px 0 40px;
        color: rgb(0, 0, 0);
        background: url(./images/invisible.png) 12px 8px no-repeat;
        background-size: 22px 22px;
      }
      .active {
        color: #fff;
        background: url(./images/visible.png) no-repeat 12px 8px #1b8cf2;
        background-size: 22px 22px;
      }
    }
    .title-wrapper {
      padding: 30px 20px 0;
    }
    .title {
      vertical-align: middle;
      margin: 0 15px;
    }
    .module-wrapper {
      padding: 20px 0;
    }
    .module-list {
      margin-bottom: 20px;
      .modules-header {
        background: #e3f0ff;
        display: flex;
        .module {
          border: 2px solid #fff;
          padding: 20px;
          display: flex;
          align-items: center;
          flex: 1;
          .module-c-box {
            width: 40px;
          }
          .module-name {
            flex: 1;
          }
          &.module-col {
            flex-direction: column;
          }
          &.module-0 {
            flex: 0 0 150px;
          }
          &.module-1 {
            flex: 1;
          }
        }
      }
      .modules {
        background: #fff;
        display: flex;
        &:nth-of-type(even) {
          background: #f2f2f2;
        }
        &:nth-of-type(odd) {
          background: #f8f8f8;
        }
        .module {
          border: 2px solid #fff;
          padding: 20px;
          display: flex;
          flex: 1;
          align-items: center;
          flex-wrap: wrap;
          .module-c-box {
            width: 40px;
          }
          .module-name {
            flex: 1;
          }
          &.module-col {
            flex-direction: column;
          }
          &.module-0 {
            flex: 0 0 150px;
          }
          &.module-1 {
            flex: 1;
          }
        }
        .role-permission-btn {
          display: inline-block;
          position: relative;
          margin-right: 0.5em;
          margin-bottom: 14px;
          line-height: 2.4em;
          text-align: center;
          min-width: 80px;
          padding: 0 15px;
          height: 35px;
          border-radius: 4px;
          border: 1px solid #aaaaaa;
          background-color: transparent;
          color: #aaa;
          cursor: pointer;
          .role-permission-text {
            white-space: nowrap;
          }
          &.selected {
            border: 1px solid #1b8cf2;
            color: #1b8cf2;
            .role-permission-text {
              color: #1b8cf2;
            }
            .role-permission-icon {
              background-image: url(./images/choosed.png);
              position: absolute;
              right: -1px;
              bottom: -1px;
              width: 18px;
              height: 12px;
              background-size: contain;
            }
          }
        }
      }
    }
  }
}
</style>
