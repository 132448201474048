var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "role-permission-wrapper" },
    [
      _c(
        "ul",
        { staticClass: "item-name-wrapper" },
        _vm._l(_vm.resource, function (item, index) {
          return _c(
            "li",
            {
              key: item.id,
              staticClass: "item-name",
              class: {
                active: index === _vm.currentIndex,
                disabled: item.disabled,
              },
              on: {
                click: function ($event) {
                  return _vm.changeCenter(index, item)
                },
              },
            },
            [
              _vm._v("\n      " + _vm._s(item.name) + "\n      "),
              item.moduleColCheckedLength
                ? _c("span", { staticClass: "dot" })
                : _vm._e(),
            ]
          )
        }),
        0
      ),
      _vm._l(_vm.resource, function (item, index) {
        return _c(
          "div",
          {
            key: item.id,
            staticClass: "item-list-wrapper",
            class: { active: index === _vm.currentIndex },
          },
          [
            _c(
              "div",
              { staticClass: "menu-wrapper" },
              _vm._l(item.childMenus, function (menus) {
                return _c(
                  "div",
                  {
                    key: menus.id,
                    staticClass: "menu-list",
                    class: { active: menus.isSelected === 1 },
                    on: {
                      click: function ($event) {
                        return _vm.toggleMenu(menus, item)
                      },
                    },
                  },
                  [_vm._v(_vm._s(menus.name))]
                )
              }),
              0
            ),
            _c(
              "div",
              { staticClass: "module-wrapper" },
              _vm._l(item.childMenus, function (menus) {
                return _c("div", { key: menus.id }, [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: menus.isSelected === 1,
                          expression: "menus.isSelected===1",
                        },
                      ],
                      staticClass: "module-list",
                    },
                    [
                      _c("div", { staticClass: "modules-header" }, [
                        _c("div", { staticClass: "module module-0" }, [
                          _c(
                            "h3",
                            { staticClass: "inline-block v-middle mg_0 pl_20" },
                            [_vm._v(_vm._s(menus.name))]
                          ),
                        ]),
                        _c("div", { staticClass: "module module-1" }),
                      ]),
                      _vm._l(menus.childMenus, function (menuItems) {
                        return [
                          _c(
                            "div",
                            { key: menuItems.id, staticClass: "modules" },
                            [
                              _c("div", { staticClass: "module module-0" }, [
                                _c(
                                  "h3",
                                  {
                                    staticClass:
                                      "inline-block v-middle mg_0 pl_20",
                                  },
                                  [_vm._v(_vm._s(menuItems.name))]
                                ),
                              ]),
                              _c(
                                "div",
                                { staticClass: "module module-1" },
                                _vm._l(menuItems.childMenus, function (menu) {
                                  return _c("div", { key: menu.id }, [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "role-permission-btn",
                                        class: {
                                          selected: menu.isSelected === 1,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.changeMenuSelectedVal(
                                              menu,
                                              menus,
                                              item
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "role-permission-text",
                                          },
                                          [_vm._v(_vm._s(menu.name))]
                                        ),
                                        _c("span", {
                                          staticClass: "role-permission-icon",
                                        }),
                                      ]
                                    ),
                                  ])
                                }),
                                0
                              ),
                            ]
                          ),
                        ]
                      }),
                    ],
                    2
                  ),
                ])
              }),
              0
            ),
          ]
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }