<template>
  <div class="menuMgrForm-container">
    <form-panel ref="formPanel"
                :form="form"
                v-bind="submitConfig"
                :submitBefore="submitBefore"
                @update="update">
      <col2-block title="基础信息">
        <el-form-item label="应用包名称"
                      prop="appPackageName"
                      :rules="[{ required: true, message: '请填写应用包名称', trigger: 'blur' }]">
          <v-input v-model="form.appPackageName"
                   :width="300"
                   :maxlength="10"
                   placeholder="请输入应用包名称"></v-input>
        </el-form-item>
        <el-form-item label="应用包图标"
                      prop="appIcon"
                      :rules="[{ required: true, message: '请选择应用包图标', trigger: 'change' }]">
          <v-uploader :action="uploadURL"
                      :imgUrls.sync="form.appIcon">
            <template #tip>
              <span>图片大小以132*132显示最佳</span>
            </template>
          </v-uploader>
        </el-form-item>
        <el-form-item label="包含菜单"
                      prop="platform"
                      :rules="[{ required: true, message: '请填勾选包含菜单', trigger: 'change' }]">
          <el-radio-group v-model="form.platform"
                          v-if="platformOps.length>0">
            <el-radio-button :label="item.value"
                             v-for="item in platformOps"
                             :key="item.value">{{item.text}}</el-radio-button>
          </el-radio-group>
          <div class="package-url-wrapper">
            <application-package-url v-if="!id"
                                     ref="permissionsRefAdd"
                                     :unifyId="form.platform"
                                     :roleDataList="rolePermissionsList"
                                     handleType="add"></application-package-url>
            <application-package-url v-else
                                     ref="permissionsRefEdit"
                                     :unifyId="form.platform"
                                     :roleDataList="rolePermissionsList"
                                     handleType="edit"></application-package-url>
          </div>
        </el-form-item>
      </col2-block>
    </form-panel>
  </div>
</template>
<script>
import {
  addMenuURL,
  getRoleDetailURL,
  getViewMenuURL,
  uploadURL,
  editURL
} from './api'
import { Col2Block } from '@/components/bussiness'
import applicationPackageUrl from './applicationPackageUrl'
import { vUploader } from 'components/control'
export default {
  name: 'MenuMgrForm',
  components: {
    Col2Block,
    applicationPackageUrl,
    vUploader
  },
  data () {
    return {
      id: this.$route.query.id,
      creater: this.$route.query.creater,
      queryUrl: getRoleDetailURL,
      uploadURL,
      submitUrl: addMenuURL,
      platformOps: [],
      rolePermissionsList: [],
      form: {
        platform: undefined,
        id: this.$route.query.id,
        appPackageName: '',
        menuIds: '',
        appIcon: ''
      },
      submitConfig: {
        queryUrl: getRoleDetailURL,
        submitUrl: addMenuURL,
        submitMethod: 'POST'
      }
    }
  },
  watch: {
  },
  computed: {
  },
  mounted () {
    let { id } = this.$route.query
    this.id = id
    if (id !== undefined) {
      this.submitConfig.submitUrl = editURL
      this.submitConfig.submitMethod = 'PUT'
      if (id) {
        this.$setBreadcrumb('编辑')
      }
      this.$refs.formPanel.getData({ packageId: id })
    } else {
      this.$setBreadcrumb('新增')
      this.getMenuData()
    }
  },
  methods: {
    getMenuData () {
      this.$axios.get(getViewMenuURL).then(res => {
        if (res.status === 100) {
          if (res.data && res.data.resource && res.data.resource.length) {
            let menusList = res.data.resource
            this.roleList = menusList
            this.rolePermissionsList = menusList
            this.platformOps = menusList.map(item => {
              return {
                text: item.name,
                value: item.id
              }
            })
            this.form.platform = this.platformOps[0].value
          }
        }
      })
    },
    update (data) {
      let { resource, packageInfo } = data
      this.rolePermissionsList = resource
      this.roleList = resource
      this.platformOps = resource.map(item => {
        return {
          text: item.name,
          value: item.id
        }
      })
      if (this.id) {
        this.backData = packageInfo
        this.form = {
          appPackageName: packageInfo.appPackageName,
          appIcon: packageInfo.appIcon,
          menuIds: '',
          id: this.id,
          platform: this.platformOps[0].value
        }
      }
    },
    submitBefore (data) {
      let permissionsName = this.id ? 'permissionsRefEdit' : 'permissionsRefAdd'
      let ids = this.$refs[permissionsName].getResultData()
      data.menuIds = ids
      if (ids.length > 10) {
        this.$message.warning('包含菜单最多只能勾选10个')
        return false
      }
      if (ids.length === 0) {
        this.$message.warning('请选择包含菜单')
        return false
      }

      // 操作日志的数据对象参数
      let creater = this.creater ? this.creater : this.$store.getters.getUserInfo.userName
      let dataObject = `${data.appPackageName}-${creater}`
      this.$set(data, 'dataObject', dataObject)

      return true
    }
  }
}
</script>

<style lang="scss" scoped>
.menuMgrForm-container {
  height: 100%;
}
::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner {
  color: #409eff;
  background: #fff;
}
</style>
