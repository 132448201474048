var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "menuMgrForm-container" },
    [
      _c(
        "form-panel",
        _vm._b(
          {
            ref: "formPanel",
            attrs: { form: _vm.form, submitBefore: _vm.submitBefore },
            on: { update: _vm.update },
          },
          "form-panel",
          _vm.submitConfig,
          false
        ),
        [
          _c(
            "col2-block",
            { attrs: { title: "基础信息" } },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "应用包名称",
                    prop: "appPackageName",
                    rules: [
                      {
                        required: true,
                        message: "请填写应用包名称",
                        trigger: "blur",
                      },
                    ],
                  },
                },
                [
                  _c("v-input", {
                    attrs: {
                      width: 300,
                      maxlength: 10,
                      placeholder: "请输入应用包名称",
                    },
                    model: {
                      value: _vm.form.appPackageName,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "appPackageName", $$v)
                      },
                      expression: "form.appPackageName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "应用包图标",
                    prop: "appIcon",
                    rules: [
                      {
                        required: true,
                        message: "请选择应用包图标",
                        trigger: "change",
                      },
                    ],
                  },
                },
                [
                  _c("v-uploader", {
                    attrs: { action: _vm.uploadURL, imgUrls: _vm.form.appIcon },
                    on: {
                      "update:imgUrls": function ($event) {
                        return _vm.$set(_vm.form, "appIcon", $event)
                      },
                      "update:img-urls": function ($event) {
                        return _vm.$set(_vm.form, "appIcon", $event)
                      },
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "tip",
                        fn: function () {
                          return [
                            _c("span", [_vm._v("图片大小以132*132显示最佳")]),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "包含菜单",
                    prop: "platform",
                    rules: [
                      {
                        required: true,
                        message: "请填勾选包含菜单",
                        trigger: "change",
                      },
                    ],
                  },
                },
                [
                  _vm.platformOps.length > 0
                    ? _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.form.platform,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "platform", $$v)
                            },
                            expression: "form.platform",
                          },
                        },
                        _vm._l(_vm.platformOps, function (item) {
                          return _c(
                            "el-radio-button",
                            { key: item.value, attrs: { label: item.value } },
                            [_vm._v(_vm._s(item.text))]
                          )
                        }),
                        1
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "package-url-wrapper" },
                    [
                      !_vm.id
                        ? _c("application-package-url", {
                            ref: "permissionsRefAdd",
                            attrs: {
                              unifyId: _vm.form.platform,
                              roleDataList: _vm.rolePermissionsList,
                              handleType: "add",
                            },
                          })
                        : _c("application-package-url", {
                            ref: "permissionsRefEdit",
                            attrs: {
                              unifyId: _vm.form.platform,
                              roleDataList: _vm.rolePermissionsList,
                              handleType: "edit",
                            },
                          }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }